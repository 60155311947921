<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>{{ $t("meetings") }}</h1>
    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="meetings">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <!-- <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
          </div> -->
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ meetings.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : meetings.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=60">
              <span>60</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=80">
              <span>80</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="scheduled_date">{{ $t("scheduled_date") }}</vs-th>
        <vs-th sort-key="mission">{{ $t("mission") }}</vs-th>
        <vs-th sort-key="is_satisfied">{{ $t("is_satisfied") }}</vs-th>
        <vs-th sort-key="created_at">{{ $t("created_at") }}</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.scheduled_date | date_time}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.mission.poNumber}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{is_satisfiedObject[tr.is_satisfied]}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.created_at | date_time}}</p>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <div class="flex">

              <vx-tooltip text="Voir" color="primary">
                <feather-icon 
                  style="color: #0627bb" 
                  icon="EyeIcon" 
                  svgClasses="w-5 h-5 hover:text-primary stroke-current" 
                  class="mr-2" 
                  @click="ShowData(tr)"
                  />
              </vx-tooltip>

           <!--    <vx-tooltip :text="$t('Modifier')" color="warning">
                <feather-icon
                  style="color: #FF9F43"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-warning stroke-current"
                  class="mr-2"
                  @click="editData(tr)"
                />
              </vx-tooltip>
              <vx-tooltip :text="$t('Supprimer')" color="danger">
                <feather-icon
                  @click="deleteData(tr.id)"
                  style="color: red"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="mr-2"
                />
              </vx-tooltip> -->
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-popup
       title="Motifs"
        :active.sync="activePrompt">
      <vx-card class="px-4" >
        <!-- MAIL HEAD -->
        <div class="vx-row">
          <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
            <!-- Sender Details -->
            <div class="flex items-center">
              <!-- <div>
                <vs-avatar class="sender__avatar--single flex-shrink-0 mr-3 border-2 border-solid border-white" size="65px" />
              </div> -->
              <div class="flex flex-col">
                <h5  class="mb-1">{{mission}}</h5>
              </div>
            </div>
            <!-- /Sender Details -->

            <!-- Mail Time & Date -->
            <div class="flex flex-col justify-end">
              <span class="text-right">{{ scheduled_date| date_time }}</span>
              <div class="flex justify-end mt-2" v-if="is_satisfied === false">
                <div class="open-mail-label flex items-center ">
                  <div class="h-3 w-3  rounded-full bg-danger mr-2" ></div>
                  <span class="text-right" >{{ $t("Non") }}</span>
                </div>
              </div>
              <div class="flex justify-end mt-2" v-if="is_satisfied === true">
                <div class="open-mail-label flex items-center ">

                  <div class="h-3 w-3  rounded-full bg-success mr-2" ></div>
                  <span class="text-right" >{{ $t("Oui") }}</span>
                </div>
              </div>

             <!--  <div class="flex justify-end mt-2">
                <span class="text-right">{{created_at | moment }}</span>
              </div> -->
            </div>
          </div>
          <!-- /Mail Time & Date -->
        </div>

        <!-- MAIL CONTENT -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mail__content break-words mt-8 mb-4" v-html="reason"></div>
          </div>

        </div>
      </vx-card>

    </vs-popup>
  </div>
</template>
<script>


export default {

  data () {
    return {
      activePrompt :false,
      is_satisfiedObject:{
        true: 'Oui',
        false:'Non'
      },
      meetings: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      reason:'',
      mission:'',
      scheduled_date:'',
      is_satisfied:'',
      created_at:''
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.meetings.length
    }
  },
  methods: {
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`meetings/${id}/`)
            .then(response => {
              this.getAllmeetings()
              window.getPrendTaCom.success(this.$t('Suppressionmeeting'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })

    },
    ShowData (tr) {
      this.reason = tr.reason
      this.mission = tr.mission.poNumber
      this.scheduled_date = tr.scheduled_date
      this.is_satisfied = tr.is_satisfied
      this.created_at = tr.created_at
      this.activePrompt = true
    },
    getAllmeetings () {
      this.$vs.loading()
      this.$http.get('meetings/')
        .then((response) => {
          this.meetings = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  async created () {
    window.meetings = this
    this.getAllmeetings()

  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

